<template>
  <v-card
    class="mt-2"
    rounded="xl"
    :ripple="false"
    :to="to"
  >
    <v-card-text>
      <v-row
        no-gutters
        class="align-center"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 7"
          class="text-truncate"
        >
          <ul>
            <li>
              <b>{{ $t('col-cdo-no') }}</b>
              <span>: {{ item.no }}</span>
            </li>
            <li>
              <b>{{ $t('col-issue-date') }}</b
              ><span>: {{ $filters.formatDate(item.issueDate) }}</span>
            </li>
            <li>
              <b>B/L</b
              ><span>: {{  item.billLadingNo }}</span>
            </li>
            <li>
              <b>Consignee</b><span>: {{ item.consigneeCode || 'Chưa có' }}</span>
            </li>

          </ul>
        </v-col>
        <v-col
          cols="5"
          class="text-center"
        >
          <v-btn
            color="red"
            small
            dark
            outlined
            tag="a"
            target="_blank"
            :href="url"
          >
            <v-icon left>mdi-file-pdf-box</v-icon>
            Xem file</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
    },
  },
  components: {},
  data: () => ({}),
  computed: {
    url() {
      return `${process.env.VUE_APP_VSL_API}/v1/container-delivery-orders/files?delivery_no=${this.item.no}`;
    },
  },
  async created() {},
  methods: {},
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
  padding-left: 8px;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 125px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
