var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-2",
      attrs: { rounded: "xl", ripple: false, to: _vm.to },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 7 },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("col-cdo-no")))]),
                      _c("span", [_vm._v(": " + _vm._s(_vm.item.no))]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v(_vm._s(_vm.$t("col-issue-date")))]),
                      _c("span", [
                        _vm._v(
                          ": " +
                            _vm._s(_vm.$filters.formatDate(_vm.item.issueDate))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("B/L")]),
                      _c("span", [
                        _vm._v(": " + _vm._s(_vm.item.billLadingNo)),
                      ]),
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Consignee")]),
                      _c("span", [
                        _vm._v(
                          ": " + _vm._s(_vm.item.consigneeCode || "Chưa có")
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red",
                        small: "",
                        dark: "",
                        outlined: "",
                        tag: "a",
                        target: "_blank",
                        href: _vm.url,
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-file-pdf-box"),
                      ]),
                      _vm._v(" Xem file"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }